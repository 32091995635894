<template>
  <div>
    <Breadcrumbs
      page_title="expertcons"
      :items="breadcrumbs"
      :item_no="item_no ? item_no : 0"
    />
    <v-card class="mx-3 my-5" style="border-radius: 16px">
      <v-container>
        <v-row justify="space-between" class="ma-1">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              clearable
              style="border-radius: 8px"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-progress-circular
          v-if="actionloading"
          indeterminate
          class="loading-circle"
          color="green"
        ></v-progress-circular>
        <v-row class="px-0">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :search="search"
              class="exprt-table"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.no="{ item }">
                <p style="width: 33px">{{ item.no }}</p>
              </template>
              <template v-slot:item.title="{ item }">
                <p style="width: 130px">{{ item.title }}</p>
              </template>
              <template v-slot:item.empid="{ item }">
                <p style="width: 90px">{{ item.empid }}</p>
              </template>
              <template v-slot:item.sendDate="{ item }">
                <p style="width: 150px">{{ item.sendDate }}</p>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="editbtn">
                      <v-icon style="color: #424242">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="d-flex flex-column">
                    <v-btn
                      color="#424242"
                      style="font-size: 16px"
                      text
                      class="text-capitalize"
                      @click="prepareReply(item)"
                    >
                      <v-icon left>mdi-message-arrow-right-outline</v-icon>
                      {{ $t("reply") }}
                    </v-btn>
                    <v-btn
                      color="error"
                      style="font-size: 16px"
                      text
                      class="text-capitalize"
                      @click="checkReplyDelete(item)"
                    >
                      <v-icon left>mdi-trash-can-outline</v-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="9" md="9" xl="10">
            <div class="d-flex text-left pl-1">
              <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="3"
            md="3"
            xl="2"
            class="d-flex justify-end"
          >
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-3 mr-3"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="replydialog" persistent width="1100">
      <v-card class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title style="font-size: 25px; color: #363636; height: 84px">
            <v-progress-circular
              v-if="actionloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
            <v-img
              src="@/assets/Iconly-Light-Outline-Send.svg"
              width="25"
              class="QA"
            ></v-img>
            <p style="margin-left: 40px">Q&A</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="pt-6">
              <v-row wrap align-center mb-4>
                <v-col
                  cols="12"
                  lg="2"
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: #4d4f5c;
                    opacity: 1;
                  "
                  >{{ $t("fullnamesm") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ userName }}</v-col>
              </v-row>
              <v-row align-center mb-4>
                <v-col>
                  <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                    {{ $t("titlenameM") }}
                  </p>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="titleName"
                    class="rounded-lg"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                    {{ $t("answer") }}
                    <span style="color: red">*</span>
                  </p>
                  <v-textarea
                    outlined
                    :rules="replyRules"
                    required
                    name="input-7-4"
                    v-model="reply"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row></v-row>
            </div>
          </v-card-text>

          <v-card-actions class="pb-5 px-4">
            <v-checkbox
              v-model="isFrequentQuestion"
              :label="$t('FAQ')"
              color="#A6CC39"
              hide-details
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mt-4"
              width="100"
              style="font-size: 16px; color: #424242"
              text
              @click="(replydialog = false), $refs.form.reset()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="text-capitalize white--text mt-4 sendbtn"
              width="100"
              color="#A6CC39"
              style="font-size: 16px"
              @click="addReply()"
              >{{ $t("send") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent width="587">
      <v-card>
        <v-card-title
          style="font-size: 30px; color: #ffffff; height: 84px"
          class="secondary justify-center"
        >
          <p>{{ $t("deleteconfirm") }}</p>
        </v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 16px; color: #4d4f5c; text-align: center"
          >
            {{ $t("suredelete") }} "Question #{{ editData.no }}" ?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 20px"
            text
            outlined
            @click="dialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            color="error"
            style="font-size: 20px"
            @click="deleteData()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successDialog" persistent width="587">
      <v-card>
        <v-card-title
          style="font-size: 30px; color: #ffffff; height: 84px"
          class="secondary justify-center"
        >
          <p>{{ $t("success") }}</p>
        </v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 16px; color: #4d4f5c; text-align: center"
          >
            {{ $t("sendinganswer") }}
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            class="text-capitalize mx-auto white--text"
            width="100"
            color="#A6CC39"
            style="font-size: 20px"
            @click="successDialog = false"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
    <v-dialog v-model="deletesuccessDialog" persistent width="587">
      <v-card>
        <v-card-title
          style="font-size: 30px; color: #ffffff; height: 84px"
          class="secondary justify-center"
        >
          <p>{{ $t("success") }}</p>
        </v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 16px; color: #4d4f5c; text-align: center"
          >
            {{ $t("deleteqa") }}.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            class="text-capitalize btnfont-style mx-auto white--text"
            width="100"
            color="#A6CC39"
            @click="deletesuccessDialog = false"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data: () => ({
    item_no: 0,
    permissionDialog: false,
    permissionMessage: "",
    replydialog: false,
    breadcrumbs: [
      {
        // image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "community",
        href: "/home/community",
      },
      {
        text: "expertcons",
        // href: "/home/community"
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    editData: {},
    dialog: false,
    successDialog: false,
    deletesuccessDialog: false,
    message: "",
    search: null,
    question: "",
    empid: "",
    userName: "",
    reply: "",
    isFrequentQuestion: false,
    replyData: {},
    replyRules: [(v) => !!v || "Reply is required"],
    valid: true,
    loading: false,
    actionloading: false,
    titleName: "",
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async checkReplyDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("community");
      if (checkPermission) {
        self.dialog = true;
        self.editData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to delete Community Expert Consultant.";
      }
    },
    async getData() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        await self.$axios
          .get(
            `${self.web_url}QNA/GetQuestions?id=` +
              localStorage.getItem("companyID") +
              "&offset=" +
              new Date().getTimezoneOffset()
          )
          .then(function (res) {
            self.items = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              sendDate: moment(v.sendDate).format("DD/MM/YYYY HH:mm"),
            }));
            self.item_no = self.items.length;
          })
          .catch(function (err) {
            alert(err);
          });
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Community Expert Consultant.";
      }
      self.loading = false;
    },

    async addReply() {
      let self = this;
      self.actionloading = true;
      if (this.$refs.form.validate()) {
        await self.$axios
          .post(`${self.web_url}QNA/AddReply`, {
            id: self.replyData.id,
            reply: self.reply,
            title: self.titleName,
            notiFlag: false,
            postActiveFlag: self.isFrequentQuestion,
            companyId: localStorage.getItem("companyID"),
            fullname: localStorage.getItem("userName"),
          })
          .then(function (res) {
            if (res.data.status == 0) {
              self.replydialog = false;
              self.successDialog = true;
              self.getData();
            }
          })
          .catch(function (err) {
            alert(err);
          });
        self.$refs.form.reset();
        self.actionloading = false;
      }
    },

    async deleteData() {
      let self = this;
      await self.$axios
        .post(`${self.web_url}QNA/RemoveQuestion`, {
          id: self.editData.id,
        })
        .then(function (res) {
          self.message = res.data.message;
          self.deletesuccessDialog = true;
          self.dialog = false;
          self.getData();
        })
        .catch(function (err) {
          alert(err);
        });
    },

    async prepareReply(item) {
      let self = this;
      let checkPermission = self.check_Permissions("community");
      if (checkPermission) {
        for (const [key, value] of Object.entries(item)) {
          if (key != "activeFlag" && key != "notiFlag" && key != "postDate") {
            switch (key) {
              case "postActiveFlag":
                self.isFrequentQuestion = value;
                break;
              case "userID":
                self.originalUserID = value;
                break;
              case "question":
                break;
              case "sendDate":
                break;
            }
            self[key] = value;
          }
        }
        self.reply = item.reply == null ? "" : item.reply;
        self.replydialog = true;
        self.replyData = item;
        self.titleName = item.title;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to reply";
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "no",
          sortable: true,
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("titlenameM"),
          value: "title",
          sortable: true,
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("questionsm"),
          value: "question",
          align: "left",
          sortable: true,
          class: "tableColor",
        },
        {
          text: this.$t("answers"),
          value: "showAnswer",
          align: "left",
          sortable: true,
          class: "tableColor",
        },
        {
          text: this.$t("employeeId"),
          value: "userID",
          sortable: true,
          align: "left",
          class: "tableColor",
          // width: "160px"
        },
        {
          text: this.$t("fullnamesm"),
          value: "userName",
          sortable: true,
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("senddate"),
          value: "sendDate",
          sortable: true,
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("action"),
          value: "actions",
          sortable: false,
          align: "center",
          class: "tableColor",
        },
        // {
        //   text: "",
        //   value: "",
        //   sortable: false,
        //   class: "tableColor",
        // },
      ];
    },
  },
};
</script>
<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .exprt-table {
  margin-left: 16px;
  margin-right: 16px;
}
/* >>> .exprt-table tr:nth-child(even) {
  background: #e0e0e0;
} */
::v-deep .exprt-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .exprt-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #424242 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style>
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.editbtn {
  width: 40px !important;
  min-width: 40px !important;
  border: 1px solid #f4f4f4;
}
.QA {
  position: absolute;
  top: 40px;
}
.sendbtn:hover {
  background: #4fb14e;
}
.v-application p {
  margin-bottom: 0px !important;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: unset !important;
  }
}
</style>
